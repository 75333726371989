import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ROUTE_DEFINITIONS} from './router.constants';

@NgModule({
    imports: [RouterModule.forRoot(ROUTE_DEFINITIONS, { useHash: false })],
    exports: [RouterModule],
    providers: []
})
export class BaseRouterModule {

    constructor() {
    }
}
