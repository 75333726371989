import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService} from '@relayter/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthorizeService {

    constructor(private http: HttpClient) {

    }

    /**
     * Get a new access token
     * It's assumed the refresh_token is provided by the api as a HttpOnly cookie and should therefor be sent automatically
     * @return {Observable<string>}
     */
    public authorize(): Observable<string> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_AUTHORIZE
        ]);
        // Request a new access token with a refresh token (stored in HttpOnly cookie)
        return this.http.post<any>(url, 'grant_type=refresh_token', {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
        }).pipe(map((res) => res.data.access_token));
    }

}
