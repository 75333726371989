import {canActivate} from '../pages/relayter/routes/guards/auth-guard';
import {Routes} from '@angular/router';
import {NotFoundComponent} from '../pages/not-found/not-found.component';
import {OnboardingContainerComponent} from '../pages/onboarding/onboarding-container.component';
import {UnauthorizedComponent} from '../pages/unauthorized/unauthorized.component';

export const ROUTE_DEFINITIONS: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('../pages/onboarding/onboarding.module').then(m => m.OnboardingModule)
    },
    {
        path: 'authorize-device',
        loadChildren: () => import('../pages/device-code/device-code.module').then(m => m.DeviceCodeModule),
        canActivate: [() => canActivate]
    },
    {
        path: 'delivery',
        loadChildren: () => import('../pages/delivery/delivery.module').then(m => m.DeliveryModule)
    },
    {
        path: '',
        loadChildren: () => import('../pages/relayter/relayter.module').then(m => m.RelayterModule),
        canActivate: [() => canActivate]
    },
    {
        path: 'unauthorized',
        component: OnboardingContainerComponent,
        children: [
            {
                path: '',
                component: UnauthorizedComponent
            }
        ]
    },
    {
        path: '**',
        component: OnboardingContainerComponent,
        children: [
            {
                path: '',
                component: NotFoundComponent
            }
        ]
    }
];
